module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-asset-path/gatsby-browser.js'),
      options: {"plugins":[],"fileTypes":["js","webmanifest","json","map","css","png","txt"],"paths":["page-data","legal","travel-supplies","blog"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Honeymoon Travel Agency","short_name":"Honeymoon Travel Agency","lang":"en-US","orientation":"any","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"standalone","icon":"./src/images/honeymoon/favicon.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"c7da35048b4e9bf7221131575f36d50f"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
